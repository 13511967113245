<template>
  <loader-view v-if="loading" />

  <v-container v-else class="py-12">
    <CollectdRentsChart
      class="mb-12"
      :chartData="chartData"
      @submit="getDashboard($event)"
    />

    <RentalsOverview class="mb-12" :properties="properties" :values="earning" />

    <RecentTransactions :transactions="transactions" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { request } from "@/services";
import { displayAlert } from "@/utils";
import RecentTransactions from "@/components/home/RecentTransactions.vue";
import CollectdRentsChart from "@/components/home/CollectdRentsChart.vue";
import RentalsOverview from "@/components/home/RentalsOverview.vue";

export default {
  data() {
    return {
      loading: true,
      earning: {
        total: 0,
        sevenDays: 0,
        thirtyDyas: 0,
        lastMonths: 0,
      },
      transactions: [],
      properties: [],
      chartData: [],
    };
  },

  components: {
    RecentTransactions,
    CollectdRentsChart,
    RentalsOverview,
  },

  beforeMount() {
    this.getDashboard();
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    async getDashboard(filter = 6) {
      try {
        const payload = {
          method: "getDashboard",
          mesesGrafico: filter,
        };

        await request(payload).then((res) => {
          this.earning = {
            allocatedValue: res.valorInvestido,
            sevenDays: res.semanaRendValue,
            thirtyDyas: res.lucroUltimoMesValue,
            lastMonths: res["3mesesRendValue"],
            available: 0,
          };

          this.transactions = res.ultimosRendimentos;
          this.properties = res.contratos;
          this.chartData = res.rendGrafico.reverse();
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleWithdraw() {
      this.$refs.withdraw.open();
    },

    displayAlert,
  },
};
</script>

<style lang="scss" scoped></style>
