<template>
  <v-card class="rounded-lg pa-6" outlined>
    <div class="text-h6 secondary--text mb-4">
      <span> Visão geral dos seus aportes </span>
    </div>

    <v-row>
      <v-col col="12" md="4">
        <v-card height="100%" class="rounded-lg" outlined>
          <div
            class="d-flex flex-column justify-center align-center fill-height"
          >
            <div
              class="mb-6 d-flex flex-column justify-center align-center fill-heightr"
            >
              <v-img
                :src="require(`@/assets/home/wallet.svg`)"
                max-width="10%"
              />
            </div>

            <div class="text-h3 secondary--text font-weight-bold">
              <span>
                {{ properties.length }}
              </span>
            </div>

            <div class="text-h6 secondary--text font-weight-regular">
              <span>Unidades em portfólio</span>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" md="8">
        <v-card height="100%" class="pa-4 rounded-lg" outlined>
          <div class="text-h6 secondary--text font-weight-bold">
            <span> Valores do aporte </span>
          </div>

          <v-row>
            <v-col cols="8" md="4">
              <div class="pa-3">
                <div class="text-h4 secondary--text font-weight-bold">
                  <span>
                    {{ currencyMask(values.sevenDays) }}
                  </span>
                  <span class="text-h6">
                    <span> BRL </span>
                  </span>
                </div>
                <div class="subtext--text text-body-1">
                  <span> Acumulado 7 dias </span>
                </div>
              </div>
            </v-col>
            <v-col cols="4" md="2">
              <div
                class="pa-4 d-flex flex-column justify-center align-center fill-heightr"
              >
                <v-img
                  :src="require(`@/assets/home/porcent.svg`)"
                  max-width="60%"
                />
              </div>
            </v-col>

            <v-divider class="my-6" vertical />

            <v-col cols="8" md="4">
              <div class="pa-3">
                <div class="text-h4 secondary--text font-weight-bold">
                  <span>
                    {{ currencyMask(values.thirtyDyas) }}
                  </span>
                  <span class="text-h6">
                    <span> BRL </span>
                  </span>
                </div>
                <div class="subtext--text text-body-1">
                  <span> Acumulado 30 dias </span>
                </div>
              </div>
            </v-col>
            <v-col cols="4" md="2">
              <div
                class="pa-4 d-flex flex-column justify-center align-center fill-heightr"
              >
                <v-img
                  :src="require(`@/assets/home/graphic-full.svg`)"
                  max-width="60%"
                />
              </div>
            </v-col>
          </v-row>

          <v-divider class="my-2" />

          <v-row>
            <!--texto ja corrigido-->
            <v-col cols="8" md="4">
              <div class="pa-3">
                <div class="text-h4 red--text font-weight-bold">
                  <span>
                    {{ currencyMask(values.allocatedValue) }}
                  </span>
                  <span class="text-h6">
                    <span> BRL </span>
                  </span>
                </div>
                <div class="subtext--text text-body-1">
                  <span> Valor alocado em UCS </span>
                </div>
              </div>
            </v-col>
            <v-col c cols="4" md="2">
              <div
                class="pa-4 d-flex flex-column justify-center align-center fill-heightr"
              >
                <v-img
                  :src="require(`@/assets/home/multi.svg`)"
                  max-width="60%"
                />
              </div>
            </v-col>

            <v-divider class="my-6" vertical />

            <v-col cols="8" md="4">
              <div class="pa-3">
                <div class="text-h4 secondary--text font-weight-bold">
                  <span>
                    {{ currencyMask(values.lastMonths) }}
                  </span>
                  <span class="text-h6">
                    <span> BRL </span>
                  </span>
                </div>
                <div class="subtext--text text-body-1">
                  <span> Acumulado 90 dias </span>
                </div>
              </div>
            </v-col>
            <v-col cols="4" md="2">
              <div
                class="pa-4 d-flex flex-column justify-center align-center fill-heightr"
              >
                <v-img
                  :src="require(`@/assets/home/graphic-empty.svg`)"
                  max-width="60%"
                />
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { currencyMask } from "@/utils";

export default {
  props: {
    properties: {
      type: Array,
      default: [],
    },

    values: {
      type: Object,
      default: {
        total: 0,
        sevenDays: 0,
        thirtyDyas: 0,
        lastMonths: 0,
      },
    },
  },

  methods: {
    currencyMask,
  },
};
</script>

<style lang="scss" scoped></style>
