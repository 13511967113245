<template>
  <v-card class="pa-6" outlined>
    <div class="text-h6 secondary--text mb-4">
      <span > Últimas atividades </span>
    </div>

    <v-data-table
      class="transparent custom-table"
      :headers="headers"
      :items="transactions"
      no-data-text="Nenhuma entrada encontrada"
      hide-default-footer
    >
      <template v-slot:[`item.description`]="{ item }">
        <span >
          {{ item.descricao }}
        </span>
      </template>

      <template v-slot:[`item.contract`]="{ item }">
        <router-link class="text-decoration-none" :to="`/my-properties/${item.userContratoID}`">
          <div class="d-flex flex-column">
            <span class=" overtext--text"> {{ item.nomeContrato }} </span>
            <div class="text-caption subtext--text">
              <span > #{{ item.userContratoID }} </span>
            </div>
          </div>
        </router-link>
      </template>

      <template v-slot:[`item.date`]="{ item }">
        <span >
          {{ formatDate(item.data) }}
        </span>
      </template>

      <template v-slot:[`item.value`]="{ item }">
        <v-chip class="chip" label>
          <span class="primary--text font-weight-bold">
            <span > {{ currencyMask(item.valor) }} BRL </span>
          </span>
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { currencyMask, formatDate } from "@/utils";

export default {
  data() {
    return {
      headers: [
        { text: "Transação", value: "description" },
        { text: "UCS", value: "contract" },
        { text: "Data", value: "date" },
        { text: "Valor", value: "value" },
      ],
    };
  },

  props: {
    transactions: {
      type: Array,
      default: [],
    },
  },

  methods: {
    currencyMask,

    formatDate,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-table.v-data-table > .v-data-table__wrapper > table > thead {
  background-color: #fafafa;
}

::v-deep
  .custom-table.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  border-bottom: none !important;
  height: 32px !important;
}

.chip {
  background-color: #29aa4d20 !important;
}
</style>
