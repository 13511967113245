<template>
  <v-card class="rounded-lg pa-6" outlined>
    <div class="d-flex align-center">
      <div class="text-h6">
        <span> Saldo atualizado </span>
      </div>

      <v-spacer />

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="text-none" plain small>
            {{ filters.find((e) => e.value === filter).label }}
            <v-icon class="mr-2" small>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, i) in filters"
            :key="i"
            @click="handleChangeFilter(item.value)"
          >
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div id="chart">
      <div
        v-if="loading"
        class="load-content d-flex align-center justify-center"
      >
        <loader-content />
      </div>

      <apexchart
        v-else
        type="bar"
        height="250"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { formatAmount } from "@/utils";
const moment = require("moment");
moment.locale("pt-br");

export default {
  data() {
    return {
      loading: false,
      filters: [
        {
          label: "3 últimos meses",
          value: 3,
        },
        {
          label: "6 últimos meses",
          value: 6,
        },
        {
          label: "12 últimos meses",
          value: 12,
        },
      ],
      filter: 6,
      chartOptions: {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          position: "top",
        },
        colors: ["#48AB6F"],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 5,
            columnWidth: 24,
          },
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 1,
          colors: ["#48AB6F"],
        },
      },
    };
  },

  props: {
    chartData: {
      type: Array,
      default: [],
    },
  },

  beforeMount() {
    this.changeFilter(6);
  },

  computed: {
    series() {
      const data = this.chartData.map((el) => {
        if (Array.isArray(el) && !el.length) return 0;

        const incomes = Object.keys(el).map((yld) => {
          const values = el[yld].map((val) => formatAmount(Number(val.valor)));

          return values.length
            ? values.reduce(function (sum, i) {
                return sum + i;
              })
            : 0;
        });

        return incomes.length
          ? incomes.reduce(function (sum, i) {
              return sum + i;
            })
          : 0;
      });

      return [
        {
          name: "Rendimentos",
          data: data,
        },
      ];
    },
  },

  components: {
    apexchart: VueApexCharts,
  },

  methods: {
    changeFilter(value) {
      this.filter = value;

      var n = null;
      var arrayMonths = [];

      for (var i = 0; i < this.filter; i++) {
        n = moment().subtract(i, "months").format("MMM");
        arrayMonths.push(n);
      }

      this.chartOptions.xaxis.categories = arrayMonths.reverse();
      this.$emit("submit", this.filter);

      setTimeout(() => {
        this.loading = false;
      }, 500);
    },

    handleChangeFilter(event) {
      this.loading = true;
      this.changeFilter(event);
    },
  },
};
</script>

<style lang="scss" scoped>
.load-content {
  height: 265px;
}
</style>
